export class BookingkitCheckoutApiError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, BookingkitCheckoutApiError);
        }

        this.name = 'BookingkitCheckoutApiError';
    }

    static fromServerError(error: Error) {
        return new BookingkitCheckoutApiError(`BookingkitCheckoutApiError: ${error.message}`);
    }
}