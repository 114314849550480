<script setup lang="ts">
import {computed, defineAsyncComponent, inject, onMounted, Ref, ref, watch,} from "vue";

import {FloatingWidgetType} from "../Contracts/WidgetTypes/FloatingWidgetType";
import {WIDGET_CONFIGURATION} from "../Constants/di";

const ready = ref(false);
const floatingWidget = defineAsyncComponent(() => import('../Widgets/ModalWidget/index.vue'));
const main = ref(null as HTMLElement | null);
const slotTarget = ref(null as Node | null);
const widgetConfiguration = inject<Ref<FloatingWidgetType>>(WIDGET_CONFIGURATION)!;

/**
 * Use the first slot element as the trigger for the widget
 * @constructor
 */
const UseCustomElementSlotAsWidgetTrigger = () => {
  const slotElement: undefined | HTMLSlotElement | null = main.value?.querySelector('slot');
  const slotNodes = slotElement?.assignedNodes()
  const usesSlot = slotNodes && slotNodes.length > 1;

  // Use the new configuration api with data props if at least one data prop is set

  if(usesSlot && slotElement){
    slotTarget.value = slotNodes[1];
  }



  ready.value = true;
}
onMounted(UseCustomElementSlotAsWidgetTrigger)
const readyState = inject<Ref<boolean>>('readyState')!;
const readyToRender = computed(() => ready.value && readyState.value);
watch(readyToRender, (v) => {
  if(v){

    const slotElement: undefined | HTMLSlotElement | null = main.value?.querySelector('slot');
    const slotNodes = slotElement?.assignedNodes()
    const usesSlot = slotNodes && slotNodes.length > 1;

    // Use the new configuration api with data props if at least one data prop is set
    if(usesSlot && slotElement){
      widgetConfiguration.value.trigger = [slotElement as HTMLSlotElement];
    }

  }
})
</script>

<template>
  <span ref="main">
    <slot></slot>
  </span>
  <template v-if="readyToRender">
    <teleport v-if="slotTarget" :to="slotTarget">
      <slot></slot>
    </teleport>
    <floating-widget  />
  </template>
</template>
