export class TriggerConfigurationError extends Error {
    constructor(message:string) {
        super(message);
        this.name = 'TriggerConfigurationError';
    }

    public static fromWrongType = (proposedType: string) => {
        return new TriggerConfigurationError(`The property 'trigger' must be of type HTMLElement or TriggerStyle, received ${proposedType} instead`);
    }
    public static fromInvalidStyle = (message: string) => {
        return new TriggerConfigurationError(`Invalid style provided: ${message}`);
    }

}