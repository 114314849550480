import {InvalidWidgetConfiguration} from "../Errors/InvalidWidgetConfiguration";
import {
    FLOATING_WIDGET_TYPE,
} from "../../../Contracts/WidgetTypes/FloatingWidgetType";
import {isValidFloatingWidgetConfiguration} from "./FloatingWidgetConfigurationValidator";
import {WidgetInput} from "../../../Contracts/WidgetTypes";
import {BOOK_NOW_WIDGET_TYPE} from "../../../Contracts/WidgetTypes/BookNowType";
export const isValidWidgetConfiguration = (config: WidgetInput) => {
    if(!config.id){
        throw InvalidWidgetConfiguration.fromMissingWidgetId();
    }
    switch (config.type) {
        case FLOATING_WIDGET_TYPE:
            return isValidFloatingWidgetConfiguration(config);
        case BOOK_NOW_WIDGET_TYPE:
            return true;
        default:
            throw InvalidWidgetConfiguration.fromInvalidWidgetType([ FLOATING_WIDGET_TYPE, BOOK_NOW_WIDGET_TYPE]);
    }
}