import {WidgetStateEvents,} from "../../Events/Widget/State";
import {SessionEvents} from "../../Events/Session";
import {ABTesterEvents} from "../../Events/ABTester/Events";

type EventMap = SessionEvents & WidgetStateEvents & ABTesterEvents;

class EventEmitterService<T extends Record<string, any>> {
    private listeners: { [K in keyof T]?: Array<(payload: T[K]) => void> } = {};

    // Emit an event with a payload
    emit<K extends keyof T>(eventName: K, payload: T[K]) {
        const eventListeners = this.listeners[eventName];
        if (eventListeners) {
            eventListeners.forEach(listener => listener(payload));
        }
    }
    // Listen to an event
    listen<K extends keyof T>(eventName: K, callback: (payload: T[K]) => void) {
        if (!this.listeners[eventName]) {
            this.listeners[eventName] = [];
        }
        this.listeners[eventName]!.push(callback);
    }
}

export const GlobalEventBus = new EventEmitterService<EventMap>();
