import {ALL_LOCALES, EnGB, Locale} from "../../../Constants/locales";

export const stringToLocale = (locale: string): Locale => {
    const perfectMatch = ALL_LOCALES.find(l => l === locale);
    if(perfectMatch) {
        return perfectMatch;
    }
    const twoLetterMatch = ALL_LOCALES.find(l =>{
        try {
            return  l.slice(0, 2) === locale.split('-')[0]
        } catch {
            return false;
        }
    });
    if(twoLetterMatch) {
        return twoLetterMatch;
    }
    return EnGB;
}