import {TriggerStyle, TriggerStyleInput, WidgetStyle, WidgetStyleInput} from "./Common/TriggerStyle";
import {BaseWidgetConfiguration, BaseWidgetConfigurationInput} from "./BaseWidget";
export const FLOATING_WIDGET_TYPE = 'floatingWidget';
export const FLOATING_WIDGET_CONFIGURATION_VERSION = '0.0.9';

// #region FloatingWidgetType
export interface FloatingWidgetType extends BaseWidgetConfiguration {
    type:  typeof FLOATING_WIDGET_TYPE,
    /**
     *  The experiences for which to sell tickets
     */
    experiencesForTickets: string[],
    experiencesForVouchers: string[],
    trigger: TriggerStyle | HTMLElement[],
    widgetStyle: WidgetStyle,
    id: string,
}
// #endregion FloatingWidgetType

export interface FloatingWidgetConfigurationInput extends BaseWidgetConfigurationInput {
    experiencesForTickets: string[],
    experiencesForVouchers: string[],
    trigger?: TriggerStyleInput,
    widgetStyle?: WidgetStyleInput,
    id: string,
    type:  typeof FLOATING_WIDGET_TYPE,
};


