import {TriggerConfigurationError} from "../Errors/TriggerConfigurationError";
import {TriggerStyle} from "../../../Contracts/WidgetTypes/Common/TriggerStyle";

const validatePosition = (position: any): void => {
    if(typeof position !== 'string') {
        throw TriggerConfigurationError.fromInvalidStyle(`position must be a string, provided: ${typeof position}`);
    }
    const validPositions = ['bottom-right', 'bottom-left', 'top-right', 'top-left', 'center-right', 'center-left'];
    if(!validPositions.includes(position)) {
        throw TriggerConfigurationError.fromInvalidStyle(`position must be one of ${validPositions.join(",")}, provided: ${position}`);
    }
}

const validateIcon = (icon:  any) => {
    if(typeof icon !== 'string') {
        throw TriggerConfigurationError.fromInvalidStyle(`icon must be a string, provided: ${typeof icon}`);
    }
    const validIcons = ['ticket', 'shopping-bag', 'voucher'];
    if(!validIcons.includes(icon)) {
        throw TriggerConfigurationError.fromInvalidStyle(`icon must be one of ${validIcons.join(",")}, provided: ${icon}`);
    }
}

const validateButtonSize = (buttonSize: any) => {
    if(typeof buttonSize !== 'string') {
        throw TriggerConfigurationError.fromInvalidStyle(`buttonSize must be a string, provided: ${typeof buttonSize}`);
    }
    const validButtonSizes = ['small', 'medium'];
    if(!validButtonSizes.includes(buttonSize)) {
        throw TriggerConfigurationError.fromInvalidStyle(`buttonSize must be one of ${validButtonSizes.join(",")}, provided: ${buttonSize}`);
    }
}

const validateButtonAnimation = (buttonAnimation: any) => {
    if(typeof buttonAnimation !== 'string') {
        throw TriggerConfigurationError.fromInvalidStyle(`buttonSize must be a string, provided: ${typeof buttonAnimation}`);
    }
    const validButtonAnimations = ['none', 'ripple', 'shine', 'shake'];
    if(!validButtonAnimations.includes(buttonAnimation)) {
        throw TriggerConfigurationError.fromInvalidStyle(`buttonAnimation must be one of ${validButtonAnimations.join(",")}, provided: ${buttonAnimation}`);
    }
}

const validateButtonType = (buttonType: any) => {
    if(typeof buttonType !== 'string') {
        throw TriggerConfigurationError.fromInvalidStyle(`buttonType must be a string, provided: ${typeof buttonType}`);
    }
    const validButtonTypes = ['shadow', 'flat', 'outlined'];
    if(!validButtonTypes.includes(buttonType)) {
        throw TriggerConfigurationError.fromInvalidStyle(`buttonType must be one of ${validButtonTypes.join(",")}, provided: ${buttonType}`);
    }
}

const validateButtonColour = (buttonColour: any) => {
    if(typeof buttonColour !== 'string') {
        throw TriggerConfigurationError.fromInvalidStyle(`buttonColour must be a string, provided: ${typeof buttonColour}`);
    }
    const validButtonColours = ['neutral', 'primary'];
    if(!validButtonColours.includes(buttonColour)) {
        throw TriggerConfigurationError.fromInvalidStyle(`buttonColour must be one of ${validButtonColours.join(",")}, provided: ${buttonColour}`);
    }
}
const validateButtonDistance = (buttonDistance: any) => {
    if(typeof buttonDistance !== 'string') {
        throw TriggerConfigurationError.fromInvalidStyle(`buttonDistance must be a string, provided: ${typeof buttonDistance}`);
    }
    const validButtonDistances = ['sticky', 'floating'];
    if(!validButtonDistances.includes(buttonDistance)) {
        throw TriggerConfigurationError.fromInvalidStyle(`buttonColour must be one of ${validButtonDistances.join(",")}, provided: ${buttonDistance}`);
    }
}
const validateStyle = (style: any): style is TriggerStyle => {
    if(typeof style !== 'object') {
        throw TriggerConfigurationError.fromInvalidStyle(`expected object, got ${typeof style}`);
    }
    if(style?.position) {
        validatePosition(style?.position);
    }

    if(style?.icon) {
        validateIcon(style?.icon);
    }

    if(style?.buttonSize) {
        validateButtonSize(style?.buttonSize);
    }

    if(style?.buttonAnimation) {
        validateButtonAnimation(style?.buttonAnimation);
    }

    if(style?.buttonType) {
        validateButtonType(style?.buttonType);
    }

    if(style?.buttonColor) {
        validateButtonColour(style?.buttonColor);
    }

    if(style?.buttonDistance) {
        validateButtonDistance(style?.buttonDistance);
    }

    if(style?.label) {
        if(typeof (style as Record<string, any>)?.label !== 'boolean' && typeof (style as Record<string, any>)?.label !== 'string') {
            throw TriggerConfigurationError.fromInvalidStyle(`label must be or a valid string or a boolean, provided: ${typeof style?.buttonSize}`);
        }
    }

    return true;
}
export const isValidWidgetTrigger = (trigger: any): trigger is TriggerStyle | HTMLElement[] => {
    if(trigger && (!(trigger instanceof HTMLElement) && !Array.isArray(trigger) && trigger?.constructor !== Object)) {
        throw TriggerConfigurationError.fromWrongType(typeof trigger);
    }
    if(Array.isArray(trigger)  && trigger.length === 0) {
        return true;
    }

    return validateStyle(trigger);
}