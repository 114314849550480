export const EnGB = 'en-GB';
export const ItIT = 'it-IT';
export const FrFR = 'fr-FR';
export const EsES = 'es-ES';
export const DeDE = 'de-DE';
export const CsCZ = 'cs-CZ';
export const NlNL = 'nl-NL';
export const FiFI = 'fi-FI';
export const DaDK = 'da-DK';
export const NnNO = 'nn-NO';
export const SvSE = 'sv-SE';
export const ElGR = 'el-GR';
export const JaJP = 'ja-JP';
export const PlPL = 'pl-PL';
export const PtPT = 'pt-PT';
export const ZhCN = 'zh-CN';


export type Locale = typeof EnGB | typeof ItIT | typeof FrFR | typeof EsES | typeof DeDE
     | typeof CsCZ | typeof NlNL | typeof FiFI | typeof DaDK | typeof NnNO | typeof SvSE | typeof ElGR | typeof JaJP | typeof PlPL
    | typeof PtPT | typeof ZhCN;

export const ALL_LOCALES: Locale[] = [EnGB, ItIT, FrFR, EsES, DeDE, CsCZ, NlNL, FiFI, DaDK, NnNO, SvSE, ElGR, JaJP, PlPL, PtPT, ZhCN];


export const twoLetterLanguageCodes: Record<Locale, string> = {
    [EnGB]: 'en',
    [ItIT]: 'it',
    [FrFR]: 'fr',
    [EsES]: 'es',
    [DeDE]: 'de',
    [CsCZ]: 'cs',
    [NlNL]: 'nl',
    [FiFI]: 'fi',
    [DaDK]: 'da',
    [NnNO]: 'no',
    [SvSE]: 'sv',
    [ElGR]: 'el',
    [JaJP]: 'ja',
    [PlPL]: 'pl',
    [PtPT]: 'pt',
    [ZhCN]: 'zh',
}

