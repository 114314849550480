import {Enrollment, IEnrollmentStorage} from "../index";

const ENROLLMENTS_KEY = 'bkEnrollments';
export class LocalEnrollmentStorage implements IEnrollmentStorage {
    async getAll(){
        const available = window.localStorage.getItem(ENROLLMENTS_KEY);
        if(available){
            try {
                return JSON.parse(available);
            } catch (e) {}
        }
        return [];
    }
    async persistEnrollments(enrollments: Enrollment[]) {
        window.localStorage.setItem(ENROLLMENTS_KEY, JSON.stringify(enrollments));
    }
}