// This service is responsible uses the legacy public ajax API to create a checkout session
import {CheckoutApi} from "./CheckoutApi";
import {GlobalEventBus} from "../GlobalEventBus";
import {SESSION_EVENTS} from "../../Events/Session";

type SessionServiceConstructorArgs = {
    checkoutApi: CheckoutApi,
    loggerService: ILogger,
    eventBus: typeof GlobalEventBus,
}
export class SessionService {
    private readonly checkoutApi: CheckoutApi;
    private readonly loggerService: ILogger;
    private readonly eventBus: typeof GlobalEventBus;
    private readonly sessionSeed: string;

    private sessionId: string | undefined;
    constructor({checkoutApi, loggerService, eventBus}: SessionServiceConstructorArgs) {
        this.checkoutApi = checkoutApi;
        this.loggerService = loggerService;
        this.eventBus = eventBus;
        this.sessionSeed = 'xxxxxxxx-'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        })
    }

    getSessionId(): string
    {
        if(!this.sessionId){
            this.loggerService.error(new Error('called get session when not having a valid session'))
        }
        return this.sessionSeed+this.sessionId;
    }
    async init()
    {
        const res = await this.checkoutApi.createSession();
        this.sessionId = res.id;
        this.eventBus.emit(SESSION_EVENTS.SESSION_CREATED, {sessionId: this.getSessionId()});

    }

}