export class WidgetOptionError extends Error {
    constructor(message:string) {
        super(message);
        this.name = 'WidgetOptionError';
    }
    public static fromInvalidTracking = () => {
        return new WidgetOptionError('Invalid tracking options');
    }
    public static fromWrongType = (property: string, expected: string, received: string) => {
        return new WidgetOptionError(`Invalid property ${property} with value \`${received}\` expected ${expected}`);
    }
}