export class InvalidWidgetConfiguration extends Error {
    constructor(message:string) {
        super(message);
        this.name = 'InvalidWidgetConfiguration';
    }
    public static fromInvalidWidgetType = (validTypes: string[]) => {
        return new InvalidWidgetConfiguration(`Widget configuration is missing a valid type. Accepted types are ${validTypes.join(', ')}`);
    }
    public static fromMissingWidgetId = () => {
        return new InvalidWidgetConfiguration(`Widget configuration is missing a valid widget id`);
    }
}