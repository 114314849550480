import {
    init,
    captureMessage,
    captureException,
    browserTracingIntegration,
} from '@sentry/browser';
import { version } from '../../../../package.json';
import {WIDGET_ASSET_URL} from "../../../Constants/environment";

export class SentryLogger implements ILogger {

    private loggingLevel: 'debug' | 'info' | 'warn' | 'error' = "error";
    constructor() {
        init({
            dsn: import.meta.env.VITE_SENTRY_DSN,
            release: version,
            environment: import.meta.env.MODE,
            tracesSampleRate: .1,
            allowUrls: [`${WIDGET_ASSET_URL}`],
            integrations: [
                browserTracingIntegration()
            ],
        });
    }

    setLoggingLevel(level: 'debug' | 'info' | 'warn' | 'error') {
        this.loggingLevel = level;
    }

    logEvent(event: string, message: string | Object): void {
        if (this.loggingLevel === 'debug') {
            console.log(event, message)
            //captureEvent({message, event_id: event});
        }
    }
    error(error: Error, message?: string): string {
        const errorId = captureException(error);
        if (message) {
            captureMessage(message);
        }
        return errorId;
    }
}