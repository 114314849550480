export class FloatingWidgetConfigurationError extends Error {
    constructor(message:string) {
        super(message);
        this.name = 'FloatingWidgetConfigurationError';
    }

    public static noExperiencesForTicketsOrVouchers = () => {
        return new FloatingWidgetConfigurationError('A floating widget must have at least one experience for tickets or vouchers');
    }
    public static experienceListIsNotAnArray = (property: 'experiencesForTickets' | 'experiencesForVouchers') => {
        return new FloatingWidgetConfigurationError(`Invalid property provided for ${property}, expected an array of strings`);
    }
}