// This file contains a simple di process
// Few dependencies are provided in the bootstrap file

import {App} from "vue";
import {WIDGET_ASSET_URL} from "../Constants/environment";
import {getLogger} from "../Services/LoggingService";

export const waitToHaveElement = async (app: App<any>):Promise<ShadowRoot> => {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (app._container) {
                clearInterval(interval);
                resolve(app._container);
            }
        }, 0);
    });
}

export const loadCss = () => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = `${WIDGET_ASSET_URL}assets/main.css`;
    link.onerror = () => {
        const error = new Error(`Failed to load CSS file: ${WIDGET_ASSET_URL}/assets/main.css`);
        getLogger().error(error, `${WIDGET_ASSET_URL}/assets/main.css`);
    };
    document.head.appendChild(link);
}