import {ref} from "vue";

const counter = ref(0);
class GlobalIdService {

    generateWidgetId(): string {
        counter.value++;
        return 'bkw_'+counter.value;
    }
}

export const GlobalId = new GlobalIdService();
