import posthog, {PostHogConfig} from 'posthog-js';
import {IInsights} from "../../Contracts/IInsights";
import {GlobalEventBus} from "../GlobalEventBus";
import ROUTE_NAMES from "../../Widgets/ModalWidget/Routes/constants";
import {WIDGET_STATE_EVENTS} from "../../Events/Widget/State";
import {Widget} from "../../Contracts/WidgetTypes";
import {SESSION_EVENTS} from "../../Events/Session";
import {WidgetLocationRaw} from "../../Composables/WidgetNavigation/contracts";
import {AB_TESTER_EVENTS} from "../../Events/ABTester/Events";

type Config = Partial<PostHogConfig> & {projectId: string, api_host: string};
export class PosthogAnalytics implements IInsights {
    private eventBus: typeof GlobalEventBus;
    public constructor({logger, eventBus, config}: {logger: ILogger, eventBus: typeof GlobalEventBus, config: Config}) {
        this.eventBus = eventBus;
        try {
            posthog.init(config.projectId, config);
            this.listen();
        } catch (e: any) {
            logger.error(e as Error);
        }
    }
    mapNavigationChangedEvent = (details: WidgetLocationRaw) => {
        switch (details.name) {
            case ROUTE_NAMES.BUTTON_VIEW:
                return {
                    event: 'element_view',
                    properties: {
                        element: 'floating_button.button',
                        ...details.props
                    }
                }
            case ROUTE_NAMES.EXPERIENCE_LIST:
                return {
                    event: 'page_view',
                    properties: {
                        page_title: 'floating_widget.list_widget',
                        ...details.props
                    }
                }
            case ROUTE_NAMES.DATE_TIME_SELECTION:
                return {
                    event: 'page_view',
                    properties: {
                        page_title: 'floating_widget.experience_details',
                        ...details.props
                    }
                }
            default:
                return {
                    event: 'page_view',
                    properties: {
                        ...details.props
                    }
                }
        }
    }

    listen = () => {
        let sessionUserId:string;
        const shouldTrackWidgetEvents = (options: Widget['options']) => options.collectTraces;
        this.eventBus.listen(SESSION_EVENTS.SESSION_CREATED, ({sessionId}) => {
            sessionUserId = sessionId;
        });
        this.eventBus.listen(WIDGET_STATE_EVENTS.WIDGET_MOUNTED_WITH_SUPPLIER_ID, ({supplierId}) => {
            this.identify(sessionUserId, supplierId);
        });
        this.eventBus.listen(WIDGET_STATE_EVENTS.WIDGET_MOUNTED, (event,) => {
            if(!shouldTrackWidgetEvents(event.widget.options)){return}
            this.trackEvent("widget_loaded", event);
        });
        this.eventBus.listen(WIDGET_STATE_EVENTS.WIDGET_NAVIGATION_CHANGED, (eventData,) => {
            if(!shouldTrackWidgetEvents(eventData.widget.options)){return}
            const {event, properties} = this.mapNavigationChangedEvent( eventData);
            this.trackEvent(event,properties);
        });
        this.eventBus.listen(AB_TESTER_EVENTS.ENROLLED_EXPERIMENT, (eventData,) => {
            this.trackEvent("experiment_enrollment", eventData);
        });
    }
    trackEvent = (event: string, properties?: Record<string, any>) => {
        posthog.capture(event, properties);
    }
    identify = (userId: string, supplierId: string) => {
        posthog.identify("user_"+userId, {
            vendor_ident: supplierId,
        });
    }
}
