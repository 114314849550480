export class InvalidApiV4Error extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidApiV4Error);
        }

        this.name = 'InvalidApiV4Error';
    }

    static fromMissingProperty(property: string, expectedType: string, value: unknown): InvalidApiV4Error {
        return new InvalidApiV4Error(`Unexpected response. Property \`${property}\` was invalid. Expected ${expectedType}, got ${typeof value}`);
    }
    static fromInvalidProperty(property: string, value: unknown): InvalidApiV4Error {
        return new InvalidApiV4Error(`Unexpected response. Property \`${property}\` was invalid: ${value}`);
    }
}

export default InvalidApiV4Error;
