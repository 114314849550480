export class ResponseMissingStatusCodeError extends Error {
    constructor(...params: any) {
        super(...params);
        this.message = `${this.message}`;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ResponseMissingStatusCodeError);
        }

        this.name = 'ResponseMissingStatusCodeError';
    }

    static fromNetworkError(originalError: Error) {
        return new ResponseMissingStatusCodeError('Error response without status code', originalError);
    }

    static fromOfflineClient(originalError: Error) {
        return new ResponseMissingStatusCodeError('Client is offline.', originalError);
    }
}

export default ResponseMissingStatusCodeError;
