export const LOGGER_SERVICE = 'loggerService';
export const WIDGET_SERVICE = 'loggerService';
export const INTERNAL_ANALYTICS = 'internalAnalytics';
export const EXTERNAL_ANALYTICS = 'externalAnalytics';
export const SESSION_SERVICE = Symbol('SESSION_SERVICE');
export const API_V4 = 'apiV4';
export const USE_WIDGET_EXPERIENCES = 'useWidgetExperiences';
export const USE_WIDGET = 'useWidget';
export const USE_WIDGET_NAVIGATION =  Symbol('useWidgetRouter');
export const WIDGET_CONFIGURATION =  Symbol('WIDGET_CONFIGURATION');
export const AB_TESTER_SERVICE = 'abTesterService';