import {defineCustomElement, ref} from "vue";
import ExperienceBookNowCustomElement from "../CustomElements/ExperienceBookNow.ce.vue";
import {BOOK_NOW_WIDGET_TYPE, BookNowWidgetType} from "../Contracts/WidgetTypes/BookNowType";
import {WIDGET_CONFIGURATION} from "../Constants/di";
import {getLogger} from "../Services/LoggingService";
import {boostrapCustomElementApp} from "./Bootstrap";
export const ExperienceBookNow = defineCustomElement(ExperienceBookNowCustomElement, {
    configureApp(app) {
        const readyState = ref(false);
        const widgetConfiguration = ref<Required<BookNowWidgetType>|undefined>();
        app.provide('readyState', readyState);
        app.provide(WIDGET_CONFIGURATION, widgetConfiguration);
        boostrapCustomElementApp<BookNowWidgetType>(BOOK_NOW_WIDGET_TYPE, app)
            .then(({configuration}) => {
                widgetConfiguration.value = configuration;
                readyState.value = true;
            })
            .catch((e: Error) => {
                getLogger().error(e);
            });
    },
});
