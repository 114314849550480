import {BaseWidgetConfiguration} from "../../../Contracts/WidgetTypes/BaseWidget";
// #region DefaultWidgetOptions
const defaultWidgetOptions: BaseWidgetConfiguration['options'] = {
    tracking: false,
    crashReport: true,
    collectTraces: true,
    loggingLevel: 'error',
    previewMode: false,
};
// #endregion DefaultWidgetOptions

export const applyDefaultOptions = (options: Partial<BaseWidgetConfiguration['options']>): Required<BaseWidgetConfiguration['options']> => ({
    ...defaultWidgetOptions,
    ...options
})