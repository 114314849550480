import {FLOATING_WIDGET_TYPE, FloatingWidgetType} from "../../../Contracts/WidgetTypes/FloatingWidgetType";
import {InvalidWidgetConfiguration} from "../Errors/InvalidWidgetConfiguration";
import mapFloatingWidget from "../Mappers/FloatingWidget/index";
import mapExperienceBookNowWidget from "../Mappers/ExperienceBookNow/index";
import {BOOK_NOW_WIDGET_TYPE, BookNowWidgetType} from "../../../Contracts/WidgetTypes/BookNowType";
export const hydrateDefaults = (configuration: any, instanceId: string) => {
    switch (configuration.type) {
        case FLOATING_WIDGET_TYPE:
            return mapFloatingWidget(configuration, instanceId) as Required<FloatingWidgetType>;
        case BOOK_NOW_WIDGET_TYPE:
            return mapExperienceBookNowWidget(configuration, instanceId) as Required<BookNowWidgetType>;
        default:
            throw InvalidWidgetConfiguration.fromInvalidWidgetType([ FLOATING_WIDGET_TYPE]);
    }
}
