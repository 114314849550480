export class WidgetStyleConfiguratorError extends Error {
    constructor(message:string) {
        super(message);
        this.name = 'WidgetStyleConfiguratorError';
    }

    public static fromInvalidStyle = (message: string) => {
        return new WidgetStyleConfiguratorError(`Invalid style provided: ${message}`);
    }

}