export class UncaughtApplicationError extends Error {
    constructor(message: string, stack?: string) {
        super(message);
        this.name = 'UncaughtApplicationError';

        // If a stack trace is provided, use it; otherwise, keep the default stack trace.
        if (stack) {
            this.stack = stack;
        }
    }
    public static fromUnknown(error: unknown): UncaughtApplicationError {
        if (error instanceof Error) {
            // Use the original error's message and stack trace if available
            return new UncaughtApplicationError(error.message, error.stack);
        }
        if (typeof error === 'string') {
            return new UncaughtApplicationError(error);
        }
        return new UncaughtApplicationError('Unknown error');
    }
}