import {
    Enrollment,
    Experiment, EXPERIMENT_CONTROL,
    EXPERIMENT_NA,
    IABTesterService,
    IEnrollmentStorage,
} from "./index";
import {AB_TESTER_EVENTS} from "../../Events/ABTester/Events";
import {GlobalEventBus} from "../GlobalEventBus";

export class ABTester implements IABTesterService {
    private readonly experiments: Experiment[] = [];
    private enrollments: Enrollment[] = [];

    constructor(
        private readonly enrollmentStorage: IEnrollmentStorage,
        private readonly eventBus: typeof GlobalEventBus,
    ) {
    }

    async getVariant(experiment: Experiment): Promise<string> {

        const enrollment = this
            .enrollments
            .find(x => x.experimentName === experiment.experimentName);

        if(enrollment){
            return enrollment.version;
        }
        await this.enroll(experiment);

        return this.enrollments
            .find(x => x.experimentName === experiment.experimentName)?.version
            ??
            EXPERIMENT_NA;
    }
    getAll(): Promise<Enrollment[]> {
        return Promise.resolve(this.enrollments);
    }
    async init() {
        await this.loadEnrollments();
    }
    async enroll(experiment: Experiment): Promise<void> {
        this.experiments.push(experiment);
        if(!this.enrollments.find(x => x.experimentName === experiment.experimentName)) {
            const versionToActivate = [...experiment.variants, EXPERIMENT_CONTROL][Math.floor(Math.random() * (experiment.variants.length + 1))];
            this.enrollments.push({
                experimentName: experiment.experimentName,
                version: versionToActivate
            });

            this.eventBus.emit( AB_TESTER_EVENTS.ENROLLED_EXPERIMENT,{
             experimentName: experiment.experimentName, version: versionToActivate
            })
        }
        await this.persistEnrollments();
        return Promise.resolve();
    }
    private async persistEnrollments() {
        await this.enrollmentStorage.persistEnrollments(this.enrollments);
    }

    private async loadEnrollments() {
       this.enrollments = await this.enrollmentStorage.getAll();
       for (const enrollment of this.enrollments) {
               this.eventBus.emit(
                   AB_TESTER_EVENTS.ENROLLED_EXPERIMENT,
                   {
                       ...enrollment
                   })
       }
    }

}