import 'vite/modulepreload-polyfill'
import '@bookingkit/coppi/dist/style.css';
import './style.css'
import './GlobalServices/index';

import {ExperienceBookNow} from "./bootstrap/ExperienceBookNow";
import {BootstrapFloatingWidget} from "./bootstrap/FloatingWidget";


const registerElements = () => {
    // Register the custom element.
    // After registration, all `<my-vue-element>` tags
    // on the page will be upgraded.
    customElements.define('bk-floating-widget', BootstrapFloatingWidget );
    customElements.define('bk-experience-book-now', ExperienceBookNow );

}
registerElements();

