import { AxiosError, AxiosResponse } from 'axios';
import { CanceledRequestError} from "./Errors/CanceledRequestError";
import { ResponseMissingStatusCodeError} from "./Errors/ResponseMissingStatusCodeError";
import {InvalidRequestError} from "./Errors/InvalidRequestError";

export const onFullFilled = (response: AxiosResponse) => response;

const getError = (error?: any): Error => {
    // This means that the request never reached the server,
    // It is usually the case when the request is blocked by the ad-blockers or
    // the client being offline
    if (error?.message === 'Network Error' && error?.response === undefined && ((error as AxiosError)?.code === '0' || (error as AxiosError)?.code === undefined)) {
        let isOnline = true;

        try {
            // Navigator can tell us if is offline
            isOnline = window.navigator.onLine;
        } catch {
            // but can rarely trow in some browser,
            // so better to catch the error straight away
            return ResponseMissingStatusCodeError.fromNetworkError(error);
        }
        if (isOnline) {
            // If the client is online but the request fails before reaching the EP,
            // It must be blocked from some extension / dev tool / network problem
            return ResponseMissingStatusCodeError.fromNetworkError(error);
        }

        // if we reach here, means we are offline
        return ResponseMissingStatusCodeError.fromOfflineClient(error);
    }

    if (error?.message === 'canceled') {
        return CanceledRequestError.fromCanceledRequest();
    }

    if((error as AxiosError)?.response?.status?.toString().startsWith('4') === true) {
        return InvalidRequestError.fromFourHundrishCode((error as AxiosError)?.response?.status as number, error?.message);
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return error;
}

export const makeOnReject = (logger: ILogger) => (error?: any): Promise<any> => {
    const errorInstance = getError(error);
    if(!(errorInstance instanceof CanceledRequestError)) {
        logger.error(errorInstance);
    }
    return Promise.reject(errorInstance);
};
