export interface Experiment<V extends string = string>  {
    readonly experimentName: string;
    readonly variants: V[];
}

/**
 *  An enrollment is assignment to one experiment version
 */
export type Enrollment = {
    experimentName: string,
    version: string
}

export interface IEnrollmentStorage {
    getAll(): Promise<Enrollment[]>;
    persistEnrollments(enrollments: Enrollment[]): Promise<void>;
}
export interface IEnrollmentService {
    getAll(): Promise<Enrollment[]>;
    enroll(experiment: Experiment): Promise<void>;
    getVariant<T extends Experiment>(experiment: T): Promise<T['variants'][number]>;
}
export const EXPERIMENT_NA = 'NA';
export const EXPERIMENT_CONTROL = 'control';
export interface IABTesterService {
    getAll(): Promise<Enrollment[]>;
    enroll(experiment: Experiment): Promise<void>;
    getVariant<T extends Experiment>(experiment: T): Promise<T['variants'][number]>;
}

