export class ExperienceServiceError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ExperienceServiceError);
        }

        this.name = 'ExperienceServiceError';
    }

    static fromMissingProperty(property: string, expectedType: string, value: unknown): ExperienceServiceError {
        return new ExperienceServiceError(`Required property \`${property}\` was invalid. Expected ${expectedType}, got ${typeof value}`);
    }

    static fromInvalidProperty(property: string, value: unknown): ExperienceServiceError {
        return new ExperienceServiceError(`Invalid property \`${property}\` with value ${value}`);
    }
}

export default ExperienceServiceError;
