export class CanceledRequestError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CanceledRequestError);
        }

        this.name = 'CanceledRequestError';
    }
    static fromCanceledRequest() {
        return new CanceledRequestError('This request was canceled by a cancel token');
    }
}

export default { CanceledRequestError };
