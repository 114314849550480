import {LocalLogger} from "./Loggers/LocalLogger";
import {SentryLogger} from "./Loggers/SentryLogger";

let logger: ILogger | null = null;
export const getLogger = ():ILogger => {
    if(!logger) {
        if(import.meta.env.VITE_SENTRY_DSN){
            logger = new SentryLogger();
        } else {
            logger = new LocalLogger();
        }
    }
    return logger;
}