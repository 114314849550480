export class InvalidRequestError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidRequestError);
        }

        this.name = 'InvalidRequestError';
    }
    static fromFourHundrishCode(statusCode: number, message: string): InvalidRequestError {
        return new InvalidRequestError(`Request failed with status code: \`${statusCode}\`. Message: ${message}`);
    }
}

export default { InvalidRequestError };
