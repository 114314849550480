import {MediaImage, MediaType} from "../types";
import InvalidApiV4ResponseError from "../Errors/InvalidApiV4ResponseError";

import {
    ListWidgetExperienceMediaResponseDataInner
} from "@bookingkit-private/api-v4/models/list-widget-experience-media-response-data-inner";
import {SizesResponse} from "@bookingkit-private/api-v4/models/sizes-response";

export const fromApiExperienceMedia= (expMedia: ListWidgetExperienceMediaResponseDataInner & {sort?: number, sizes?: SizesResponse}): MediaImage => {
    if(typeof expMedia.id !== 'string') {
        throw InvalidApiV4ResponseError.fromMissingProperty('id', 'string', expMedia.id);
    }
    return {
        id: expMedia.id,
        sort: expMedia.sort,
        sizes: expMedia.sizes,
        type: expMedia.type as MediaType,
    }

}