import {
    CsCZ,
    DaDK,
    DeDE,
    ElGR,
    EnGB,
    EsES,
    FiFI,
    FrFR,
    ItIT, JaJP,
    NlNL,
    NnNO, PlPL, PtPT,
    SvSE, ZhCN
} from "../../../../Constants/locales";

export const languages= [
    {
        value: CsCZ,
        label: "Čeština",
        selected: false,
    },
    {
        value: DaDK,
        label: "Dansk",
        selected: false,
    },
    {
        value: DeDE,
        label: "Deutsch",
        selected: false,
    },
    {
        value: EnGB,
        label: "English",
        selected: false,
    },
    {
        value: EsES,
        label: "Español",
        selected: false,
    },
    {
        value: FrFR,
        label: "Français",
        selected: false,
    },
    {
        value: ItIT,
        label: "Italiano",
        selected: false,
    },
    {
        value: NlNL,
        label: "Nederlands",
        selected: false,
    },
    {
        value: NnNO,
        label: "Norsk",
        selected: false,
    },
    {
        value: PlPL,
        label: "Polski",
        selected: false,
    },
    {
        value: PtPT,
        label: "Português",
        selected: false,
    },
    {
        value: FiFI,
        label: "Suomi",
        selected: false,
    },
    {
        value: SvSE,
        label: "Svenska",
        selected: false,
    },
    {
        value: ElGR,
        label: "Ελληνικά",
        selected: false,
    },
    {
        value: JaJP,
        label: "日本語",
        selected: false,
    },
    {
        value: ZhCN,
        label: "汉语",
        selected: false,
    }
];
export default languages;
