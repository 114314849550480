// This service is responsible uses the legacy public ajax API to create a checkout session
import Axios from "axios";
import {BookingkitCheckoutApiError} from "../Errors/BookingkitCheckoutApiError";

type Session = {
    id: string;
}
export class CheckoutApi {
    private readonly baseUrl: string;
    constructor({baseUrl}:{baseUrl: string}) {
        this.baseUrl = baseUrl;
    }
    async createSession(): Promise<Session> {
        try {
            const response = await Axios.get(`${this.baseUrl}GetWidgetSessionId`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return {
                id: response.data.checkout_session_id as string
            }
        } catch (e: any) {

            throw BookingkitCheckoutApiError.fromServerError(e);
        }

    }

}