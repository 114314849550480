import {defineCustomElement, ref} from "vue";
import FloatingWidgetCustomElement from "../CustomElements/FloatingWIdget.ce.vue";
import {
    FLOATING_WIDGET_TYPE,
    FloatingWidgetType
} from "../Contracts/WidgetTypes/FloatingWidgetType";
import {USE_WIDGET_EXPERIENCES, WIDGET_CONFIGURATION} from "../Constants/di";
import {getLogger} from "../Services/LoggingService";
import "@bookingkit/coppi/dist/style.css";
import "../style.css";
import {boostrapCustomElementApp} from "./Bootstrap";
import UseWidgetExperience from "../Widgets/ModalWidget/Composables/useWidgetExperience";

export const BootstrapFloatingWidget = defineCustomElement(FloatingWidgetCustomElement, {
    configureApp(app) {
        const readyState = ref(false);
        const widgetConfiguration = ref<Required<FloatingWidgetType>|undefined>();
        app.provide('readyState', readyState);
        app.provide(WIDGET_CONFIGURATION, widgetConfiguration);
        boostrapCustomElementApp<FloatingWidgetType>(FLOATING_WIDGET_TYPE, app)
            .then(({configuration, bookingkitApiService}) => {
                app.provide(USE_WIDGET_EXPERIENCES,
                    UseWidgetExperience(bookingkitApiService.experienceService,
                        [
                            ...configuration.experiencesForTickets,
                            ...configuration.experiencesForVouchers
                        ]
                    )
                );

                widgetConfiguration.value = configuration;
                readyState.value = true;
            })
            .catch((e: Error) => {
                getLogger().error(e);
            });
    },
});
