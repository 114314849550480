export const AB_TESTER_EVENTS = {
    ENROLLED_EXPERIMENT: 'enrolled_experiment',
} as const;


export type ABTesterEventConstructor = {
    [AB_TESTER_EVENTS.ENROLLED_EXPERIMENT]: {experimentName: string, version: string},
}

export type ABTesterEvents = {
    [K in keyof ABTesterEventConstructor]: ABTesterEventConstructor[K];
};