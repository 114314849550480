import {
    BUTTON_ANIMATION_NONE, BUTTON_COLOR_NEUTRAL,
    BUTTON_SIZE_MEDIUM, BUTTON_TYPE_SHADOW,
    TICKET_ICON,
} from "../../../../Contracts/WidgetTypes/Common/TriggerStyle";
import {BookNowTriggerStyleInput, BookNowTriggerStyle} from "../../../../Contracts/WidgetTypes/BookNowTriggerStyle";

const defaultTriggerStyle:BookNowTriggerStyle = {
    icon: TICKET_ICON,
    buttonSize: BUTTON_SIZE_MEDIUM,
    buttonAnimation: BUTTON_ANIMATION_NONE,
    buttonType: BUTTON_TYPE_SHADOW,
    buttonColor: BUTTON_COLOR_NEUTRAL,
    label: true
};

export const bookNowTriggerStyleMapper = (input?: Partial<BookNowTriggerStyleInput>):BookNowTriggerStyle => {
    if(!input){
        return defaultTriggerStyle;
    }
    return {
        ...defaultTriggerStyle,
        ...input
    }
}