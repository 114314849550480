import {WidgetLocationRaw} from "../../../Composables/WidgetNavigation/contracts";

const ROUTE_NAMES = {
    BUTTON_VIEW: 'ButtonViewRoute',
    EXPERIENCE_LIST : 'ExperienceListRoute',
    DATE_TIME_SELECTION : 'DateAndTimeSelectionRoute',
    CHECKOUT: 'CheckoutRoute',
};

export interface FloatingWidgetLocation extends WidgetLocationRaw {
        title: string | false,
        layout: 'modal' | false,
        switchLanguage: boolean
}
export  default ROUTE_NAMES;
