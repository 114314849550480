export class WidgetServiceError extends Error {
    constructor(...params: any) {
        super(...params);
        if (params[0] && typeof params[0] === 'string') {
            // eslint-disable-next-line prefer-destructuring
            this.message = params[0];
        }
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, WidgetServiceError);
        }

        this.name = 'WidgetServiceError';
    }

    static fromMissingProperty(property: string, expectedType: string, value: unknown): WidgetServiceError {
        return new WidgetServiceError(`Required property \`${property}\` was invalid. Expected ${expectedType}, got ${typeof value}`);
    }

    static fromInvalidProperty(property: string, value: unknown): WidgetServiceError {
        return new WidgetServiceError(`Invalid property \`${property}\` with value ${value}`);
    }
}

export default WidgetServiceError;
